import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: {light:"#FFF3E9", main: "#FDA758", dark: "#F57C00" },
      secondary: { light:"#FFFFFF", main: "#ffc546", dark: "#c79400" },
      background: { default: "#FFF3E9", paper: "#FFFFFF" },
    },
  },
  ptBR
);

export default theme;
