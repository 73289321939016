import React, { useEffect, useState } from "react";
import { Appointment } from "../../Domain";

import { Box } from "@mui/system";

import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import styled from "@mui/system/styled";
import {
  Step,
  Stepper,
  Typography,
  StepContent,
  Button,
  FormControl,
  MenuItem,
  Alert,
  InputAdornment,
} from "@mui/material";

import logoCasule from "../../Assets/Images/logo-casule.png";

import useSpecialtyLoader from "../../Hooks/useSpecialtyLoader";
import useCreatePayment from "../../Hooks/useCreatePayment";
import useProfissionalsLoader from "../../Hooks/useProfissionalsLoader";

import AvailablesSchedulesCard, { AvailablesSchedulesCardSkeleton, } from "../../Components/AvailablesSchedulesCard";

import { Footer } from "../../Components/Footer/Footer";
import { CustomStepLabel, CustomTextField } from "../../Configs/CustomStyle";

const regex = new RegExp(/[^0-9]/);

const Header = styled(Box)({
  padding: "2rem",
  display: "flex",
  justifyContent: "center",
});


const UrlPay: React.FC = () => {

  const noUse:boolean = false
  const [urlPay, setUrlPay] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const { data: specialties } = useSpecialtyLoader();
  const [findProfissionals_, resetData, { data: availablesSchedules, loading: loadingAvailableShedule },] = useProfissionalsLoader();
  const [createPayment, { data, errorMessage }] = useCreatePayment();
  const [appointment, setAppointment] = useState<Appointment>({
    date: null,
    schedule: null,
    specialty: null,
    professional: null,
    officerId: null,
    amount: null,
  });

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  
  useEffect(() => {
    setUrlPay(`${window.location.origin}/${appointment.specialty?.id}/${appointment.professional?.id}/${appointment.amount}`)
  }, [appointment]);

  useEffect(() => {
    const handlePayment = async () => {
      window.open(data?.invoiceUrl, "_blank");
    }
    data && handlePayment();
  }, [data]);

  return (
    <Container maxWidth="sm">
      <Header><img src={logoCasule} alt="Casule" width={176} height={69} /></Header>

      <Stepper activeStep={activeStep} orientation="vertical" sx={{ pb: 2 }}>

        {/* Step 1 */}
        <Step>
          <CustomStepLabel>
            Selecione a Especialidade
            {appointment.specialty && (<Typography variant="body2" color="gray"> {appointment.specialty?.name} </Typography>)}
          </CustomStepLabel>
          <StepContent>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={appointment.specialty?.id || ""}
                onChange={(event) => {
                  setAppointment({
                    ...appointment,
                    specialty: specialties.filter(({ id }) => id === event.target.value)[0],
                  });
                  findProfissionals_({ specialtyId: event.target.value });
                  handleNext();
                }}
                sx={{ mb: 2, backgroundColor: "#FFFFFF" }}
              >
                <MenuItem disabled value={""}>Selecione</MenuItem>
                {specialties.map((specialty) => (
                  specialty.id !== 188 &&
                  <MenuItem key={specialty.id} value={specialty.id}>{specialty.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <CustomStepLabel>
            Selecione o Profissional
            {
              appointment.professional ?
                (<Typography variant="body2" color="gray">{`${appointment.professional.name}`}</Typography>) :
                (appointment.specialty && (<Typography variant="body2" color="gray">{`Toque para escolher o profissional desejado`}</Typography>))
            }
          </CustomStepLabel>
          <StepContent>
            <FormControl fullWidth>
              {loadingAvailableShedule &&
                Array.of(1).map((_, index) => (
                  <AvailablesSchedulesCardSkeleton key={index} />
                ))}

              {!loadingAvailableShedule &&
                availablesSchedules.map((availableSchedule: any) => (
                  <AvailablesSchedulesCard
                    key={availableSchedule.id}
                    availableSchedule={availableSchedule}
                    onSelect={(professional) => {
                      setAppointment({
                        ...appointment,
                        professional: professional,
                        // officerId: availableSchedule.officerId,
                      });
                      handleNext();
                    }}
                  />
                ))
              }
            </FormControl>

            <Button
              variant="outlined"
              color="inherit"
              size="large"
              onClick={() => {
                setAppointment({ ...appointment, specialty: null });
                handleBack();
                resetData();
              }}
            >
              voltar
            </Button>
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <CustomStepLabel>
            Valor a pagar
            {appointment.amount && (
              <Typography variant="body2" color="gray">R${`${appointment.amount}`}</Typography>
            )}
          </CustomStepLabel>
          <StepContent>
            {errorMessage !== undefined && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'flex-start' }}>

              <FormControl fullWidth>
                <CustomTextField
                  label="Descrição"
                  name="descricao"
                  value={appointment.specialty?.name}
                  disabled={true}
                  sx={{ mb: 2 }}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomTextField
                  label="Valor"
                  sx={{ mb: 2 }}
                  name="schedule-value"
                  id="schedule-value"
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,

                  }}
                  defaultValue={appointment.professional?.rqe}
                  onChange={(e) => {
                    console.log(e.target.value)
                    let currency = e.target.value.replace(regex, '');
                    e.target.value = currency;
                  }}
                />
              </FormControl>

            </Box>

            <Button
              variant="outlined"
              color="inherit"
              size="large"
              onClick={() => {
                setAppointment({ ...appointment, professional: null });
                handleBack();
              }}
              sx={{ mr: 1 }}
            >
              voltar
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                const scheduleValue = document.getElementById('schedule-value') as HTMLInputElement;
                setAppointment({ ...appointment, amount: Number(scheduleValue.value) });
                handleNext();
              }}
              sx={{ width: '9.5rem' }}
            >
              Próximo
            </Button>



          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <CustomStepLabel>URL</CustomStepLabel>
          <StepContent>
            {errorMessage !== undefined && (<Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>)}
              {noUse && (createPayment)}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>Observação:</Typography>
              <CustomTextField
                name="observation"
                id="observation"
                label="Anote se preferir"
                multiline
                aria-readonly={true}
                rows={3}
                value={urlPay}
              />
            </FormControl>

          </StepContent>
        </Step>


      </Stepper>

      <Footer />

    </Container>
  );
};
export default UrlPay;
