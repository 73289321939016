import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Customer, ResponsiblePaymentType } from "../../Domain";
import { BillingTypeField } from "../FormFields";
import { useCookies } from "react-cookie";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ResponsiblePaymentField from "../FormFields/ResponsiblePaymentField";

import { Phone2Field } from "../FormFields/Phone2Field";
import { CustomTextField } from "../../Configs/CustomStyle";
import { mask, maskOptions } from "../../Configs/Helpers";


type CustomerFormProps = {
  onSubmit(customer: Customer): Promise<void>;
  onBack(): void;
};

const CustomerForm: React.FC<CustomerFormProps> = ({ onSubmit, onBack }) => {
  const [cookies, setCookie] = useCookies<string>([
    "ck_patient_name",
    "ck_patient_cpf",
    "ck_responsiblePayment",
    "ck_responsible_name",
    "ck_responsible_cpf",
    "ck_phone",
    "ck_email",
    "ck_cep",
    "ck_address",
    "ck_address_number",
    "ck_complement",
    "ck_district",
    "ck_city",
    "ck_state",
    "ck_billingType",
    "ck_observation"
  ]);

  const [loading, setLoading] = useState(false);
  const [responsiblePayment, setResponsiblePayment] = useState<string>(ResponsiblePaymentType.PROPRIO);

  const handlePayment = (e: string) => {
    setResponsiblePayment(e);
  }

  const optsCookies = { path: "/", maxAge: 2073600, sameSite: true };

  const handleSubmit = async (customer: Customer) => {
    if (responsiblePayment === ResponsiblePaymentType.PROPRIO) {
      customer.responsible_cpf = customer.patient_cpf;
      customer.responsible_name = customer.patient_name;
    }

    try {
      setLoading(true);
      await onSubmit(customer)
    } finally {
      setLoading(false);
    }

  };

  const onChangeWithMask = (e: any, tpMask: maskOptions) => {
    const { value } = e.target;
    e.target.value = mask(tpMask, value)
  }

  const handleFormChange = (e: any, setFieldValue: any, handleChange: any) => {
    const { name, value } = e.target;

    let nm_ck: string = `ck_${name}`;
    setFieldValue(name, value);
    setCookie(nm_ck, value, optsCookies);
    // handleChange(e);
  }

  useEffect(() => {
    const elemResponsiblePaymentField = document.querySelector('[name="responsiblePayment"]') as HTMLInputElement;
    elemResponsiblePaymentField.click();    
  }, []);


  return (
    <Formik initialValues={{
      patient_name: cookies.ck_patient_name,
      patient_cpf: mask('cpf_cnpj',cookies.ck_patient_cpf),
      responsiblePayment: ResponsiblePaymentType.PROPRIO,
      responsible_name: cookies.ck_responsible_name,
      responsible_cpf: mask('cpf_cnpj',cookies.ck_responsible_cpf),
      phone: String(cookies.ck_phone),
      email: cookies.ck_email,
      cep: cookies.ck_cep,
      address: cookies.ck_address,
      address_number: cookies.ck_address_number,
      complement: cookies.ck_complement,
      district: cookies.ck_district,
      city: cookies.ck_city,
      state: cookies.ck_state,
      billingType: "PIX"
    } as Customer} onSubmit={handleSubmit} >{
        ({ handleChange, handleSubmit, setFieldValue, values }) => (

          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} >

            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Nome Completo do Paciente"
                name="patient_name"
                id="patient_name"
                onChange={e => handleFormChange(e, setFieldValue, handleChange)}
                onLoad={e => handleFormChange(e, setFieldValue, handleChange)}
                // onKeyUp={(e: any) => setCookie("ck_patient_name", e.target.value, optsCookies)}
                value={values.patient_name}
                placeholder="Informe seu Nome Completo"
                required={true}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>

              <CustomTextField
                label="CPF/CNPJ do Paciente"
                name="patient_cpf"
                id="patient_cpf"
                onKeyUp={(e: any) => onChangeWithMask(e, 'cpf_cnpj')}
                onChange={(e: any) => handleFormChange(e, setFieldValue, handleChange)}
                value={values.patient_cpf}
                inputProps={{ maxLength: 18 }}
                placeholder="Informe seu CPF"
                required={true}
              />
            </FormControl>
            {responsiblePayment === 'PROPRIO' && (
              <>
                {/* <FormControl fullWidth sx={{ mb: 2 }}>
                  <Phone2Field
                    label="Telefone do Paciente"
                    name="phone"
                    id="phone"
                    // onChange={handleChange}
                    onChange={(e: any) => handleFormChange(e, setFieldValue, handleChange)}
                    value={values.phone}


                    placeholder="Informe seu Telefone para contato"
                    required={true}
                  />
                </FormControl> */}

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <CustomTextField
                    label="E-mail do Paciente"
                    name="email"
                    id="email"
                    onChange={(e: any) => {
                      setFieldValue("email", e.target.value)
                      setCookie("ck_email", e.target.value, optsCookies);
                    }}
                    value={values.email}
                    placeholder="Informe seu E-mail para contato"

                    required={true}
                  />
                </FormControl>
              </>
            )}

            <Typography variant="body1" sx={{ mb: 1 }}>Dados para emissão da Nota Fiscal</Typography>

            <ResponsiblePaymentField name="responsiblePayment" onChange={(e) => handlePayment(e.target.value)} sx={{ mb: 2 }} />

            {responsiblePayment === 'TERCEIRO' && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <CustomTextField
                    label="Nome do Responsável"
                    name="responsible_name"
                    id="responsible_name"
                    onChange={handleChange}
                    onKeyUp={(e: any) => setCookie("ck_responsible_name", e.target.value, optsCookies)}
                    value={values.responsible_name}
                    placeholder="Informe o Nome do Responsável"

                    required={true}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <CustomTextField
                    label="CPF/CNPJ do Responsável"
                    name="responsible_cpf"
                    id="responsible_cpf"
                    onKeyUp={(e: any) => onChangeWithMask(e, 'cpf_cnpj')}
                    onChange={(e: any) => handleFormChange(e, setFieldValue, handleChange)}
                    value={values.responsible_cpf}
                    inputProps={{ maxLength: 18 }}
                    placeholder="Informe o CPF do Responsável"
                    required={true}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <CustomTextField
                    label="E-mail do Responsável"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onKeyUp={(e: any) => setCookie("ck_email", e.target.value, optsCookies)}
                    placeholder="Informe seu E-mail para contato"
                    value={values.email}
                    required={true}
                  />
                </FormControl>
              </>
            )}

            <Typography variant="body2" sx={{ mb: 1 }}>Seu WhatsApp (Celular)*</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Phone2Field
                label="Telefone do Responsável"
                name="phone"
                id="phone"
                // onChange={handleChange}
                onChange={(e: any) => handleFormChange(e, setFieldValue, handleChange)}
                value={String(cookies.ck_phone)}
                placeholder="Informe seu Telefone para contato"
                required={true}
              />
            </FormControl>



            <Typography variant="body2" sx={{ mb: 1 }}>Endereço para a Nota Fiscal</Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="CEP"
                name="cep"
                id="cep"
                onKeyUp={(e: any) => onChangeWithMask(e, 'cep')}
                onChange={(e: any) => handleFormChange(e, setFieldValue, handleChange)}
                value={values.cep}
                inputProps={{ maxLength: 9 }}
                placeholder="Informe seu CEP"
                required={true}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Endereço"
                name="address"
                id="address"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_address", e.target.value, optsCookies)}
                value={values.address}
                placeholder="Informe seu Endereço"

                required={true}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Número"
                name="address_number"
                id="address_number"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_address_number", e.target.value, optsCookies)}
                value={values.address_number}
                placeholder="Informe seu Número"

                required={true}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Complemento"
                name="complement"
                id="complement"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_complement", e.target.value, optsCookies)}
                value={values.complement}
                placeholder="Informe seu Complemento"

              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Bairro"
                name="district"
                id="district"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_district", e.target.value, optsCookies)}
                value={values.district}
                placeholder="Informe seu Bairro"

                required={true}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Cidade"
                name="city"
                id="city"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_city", e.target.value, optsCookies)}
                value={values.city}
                placeholder="Informe sua Cidade"

                required={true}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <CustomTextField
                label="Estado"
                name="state"
                id="state"
                onChange={handleChange}
                onKeyUp={(e: any) => setCookie("ck_state", e.target.value, optsCookies)}
                value={values.state}
                placeholder="Informe seu Estado"

                required={true}
              />
              {/* <Select
              displayEmpty
              value={0}
              onChange={handleChange}
              sx={{ mb: 2 }}
              
            >
              <MenuItem disabled value={""}>Selecione</MenuItem>
              {specialties.map((specialty) => (
                <MenuItem key={specialty.id} value={specialty.id}>{specialty.name}</MenuItem>
              ))}
            </Select> */}
            </FormControl>

            <BillingTypeField name="billingType" onChange={handleChange} sx={{ mb: 2 }} />

            {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Observação:</Typography>
            <CustomTextField
              name="observation"
              id="observation"
              label="Anote se preferir"
              multiline
              onChange={handleChange}
              rows={3}
            />
          </FormControl> */}

            <Box display="flex" gap={2} flexDirection="row" sx={{ mb: 2 }}>
              <Button variant="outlined" color="inherit" size="large" onClick={() => onBack()} >Voltar</Button>
            </Box>
            <Box display="flex" gap={2} flexDirection="row">
              <LoadingButton loading={loading} type="submit" variant="contained" size="large" endIcon={<KeyboardDoubleArrowRightIcon />} >Realizar Pagamento</LoadingButton>
            </Box>
          </Box>
        )}
    </Formik>
  );
};

export default CustomerForm;
